import React from 'react';

import { useController, useTranslation } from '@wix/yoshi-flow-editor';
import { ButtonPriority } from 'wix-ui-tpa';

import { Dialog } from 'common/components/Dialog';
import { Button } from 'common/components/Button';

interface IJoinGroupProps {
  isOpen: boolean;

  onClose(): void;
}

export function JoinGroup(props: IJoinGroupProps) {
  const { t } = useTranslation();
  const { methods } = useController();

  return (
    <Dialog
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      title={t('groups-web.join.title')}
      caption={t('groups-web.join.text')}
      buttons={
        <Button
          fullWidth
          priority={ButtonPriority.primary}
          onClick={() => methods.group.redirectToGroup()}
        >
          {t('groups-web.join')}
        </Button>
      }
    />
  );
}
