import React, { useMemo, useState } from 'react';

import { useController, useTranslation } from '@wix/yoshi-flow-editor';

import {
  Popover,
  PopoverTriggerAction,
  IconButton,
  IconButtonSkins,
  ActionsMenuLayout,
} from 'wix-ui-tpa';

import { EllipsesIcon } from 'Group/Widget/icons/EllipsesIcon';
import { IFeedItem } from 'FeedWidget/api/types';
import { feedPermissionsSelector } from 'FeedWidget/Widget/Feed/selectors';
import { IControllerActions } from 'FeedWidget/types';

import { classes } from './FeedItemActions.st.css';
import { useSelector } from 'react-redux';

interface IFeedItemActionsProps {
  item: IFeedItem;
}

enum EFeedAction {
  pin = 'pin',
  unpin = 'unpin',
  follow = 'follow',
  unfollow = 'unfollow',
  share = 'share',
  edit = 'edit',
  delete = 'delete',
}

interface IFeedAction {
  type: EFeedAction;
  label: string;
  shown: boolean;
  guard?: boolean;

  onClick(): void;
}

export function FeedItemActions(props: IFeedItemActionsProps) {
  const { t } = useTranslation();
  const [shown, setShown] = useState(false);
  const { methods } = useController() as IControllerActions;
  const permissions = useSelector(feedPermissionsSelector);

  const actions = useMemo<IFeedAction[]>(
    () => [
      {
        type: EFeedAction.pin,
        label: t('groups-web.feed.post-actions.pin'),
        shown: !props.item.pin,
        guard: permissions?.canPinPosts,
        onClick: () => methods.feed.pin(props.item.feedItemId!),
      },
      {
        type: EFeedAction.unpin,
        label: t('groups-web.feed.post-actions.unpin'),
        shown: !!props.item.pin,
        guard: permissions?.canPinPosts,
        onClick: () => methods.feed.unpin(props.item.feedItemId!),
      },
      {
        type: EFeedAction.follow,
        label: t('groups-web.feed.post-actions.follow'),
        shown: !props.item.requesterContext?.subscribed,
        guard: true,
        onClick: () => methods.feed.subscribe(props.item.feedItemId!),
      },
      {
        type: EFeedAction.unfollow,
        label: t('groups-web.feed.post-actions.unfollow'),
        shown: !!props.item.requesterContext?.subscribed,
        guard: true,
        onClick: () => methods.feed.unsubscribe(props.item.feedItemId!),
      },
      {
        type: EFeedAction.share,
        label: t('groups-web.feed.post-actions.share'),
        shown: false,
        guard: props.item.permissions?.canShare,
        onClick() {},
      },
      {
        type: EFeedAction.edit,
        label: t('groups-web.feed.post-actions.edit'),
        shown: false,
        guard: props.item.permissions?.canUpdate,
        onClick() {},
      },
      {
        type: EFeedAction.delete,
        label: t('groups-web.feed.post-actions.delete'),
        shown: false,
        guard: props.item.permissions?.canDelete,
        onClick() {},
      },
    ],
    [props.item.pin, props.item.requesterContext],
  );

  const allowedActions = actions
    .filter((action) => action.shown)
    .filter((action) => action.guard);

  if (!allowedActions.length) {
    return null;
  }

  return (
    <Popover
      width={200}
      shown={shown}
      placement="bottom-end"
      triggerAction={PopoverTriggerAction.click}
      onClickOutside={toggle}
      className={classes.root}
    >
      <Popover.Element>
        <IconButton
          onClick={toggle}
          skin={IconButtonSkins.Full}
          icon={<EllipsesIcon width={24} height={24} />}
          aria-label="Actions menu"
        />
      </Popover.Element>
      <Popover.Content>
        <ActionsMenuLayout className={classes.menu}>
          {allowedActions.map((action) => (
            <ActionsMenuLayout.Item
              key={action.type}
              content={action.label}
              onClick={action.onClick}
            />
          ))}
        </ActionsMenuLayout>
      </Popover.Content>
    </Popover>
  );

  function toggle() {
    setShown(!shown);
  }
}
