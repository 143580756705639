import React from 'react';

import { Popover } from 'wix-ui-core';
import { IconToggleLabelPlacement, TPAComponentsConfig } from 'wix-ui-tpa';
import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import Picker from 'emoji-mart/dist-es/components/picker/picker';

import { Reaction } from '../Reaction';
import './EmojiPicker.global.css';
import { st, classes } from './EmojiPicker.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { AddEmojiIcon } from 'Group/Widget/icons/AddEmojiIcon';
import { Modal, ModalSkin } from 'common/components/Modal/Modal';
import { compose } from 'common/utils/compose';
import { withTPAConfig } from 'common/components/withTPAConfig';
import { withTheme, Theme } from 'common/context/theme';

interface EmojiPickerProps {
  onSelect(reactionCode: string): void;
  className?: string;
}

interface EmojiPickerState {
  isOpened: boolean;
}

class EmojiPickerComponent extends React.PureComponent<
  EmojiPickerProps & WithTranslation & TPAComponentsConfig & Theme,
  EmojiPickerState
> {
  state = {
    isOpened: false,
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  getPickerTranslations() {
    const { t } = this.props;
    const prefix = (key: string) =>
      t(`groups-web.feed.reactions.picker.${key}`);

    return {
      search: prefix('search'),
      clear: prefix('clear'),
      notfound: prefix('notfound'),
      skintext: prefix('skintext'),
      categorieslabel: prefix('categorieslabel'),
      categories: {
        search: prefix('categories.search'),
        recent: prefix('categories.recent'),
        people: prefix('categories.people'),
        nature: prefix('categories.nature'),
        foods: prefix('categories.foods'),
        activity: prefix('categories.activity'),
        places: prefix('categories.places'),
        objects: prefix('categories.objects'),
        symbols: prefix('categories.symbols'),
        flags: prefix('categories.flags'),
        custom: prefix('categories.custom'),
      },
      skintones: {
        1: prefix('skintones.1'),
        2: prefix('skintones.2'),
        3: prefix('skintones.3'),
        4: prefix('skintones.4'),
        5: prefix('skintones.5'),
        6: prefix('skintones.6'),
      },
    };
  }

  private readonly onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.closePicker();
    }
  };

  openPicker = () => {
    this.setState({ isOpened: true });
    document.addEventListener('keydown', this.onKeyDown);
  };

  closePicker = () => {
    this.state.isOpened && this.setState({ isOpened: false });
    document.removeEventListener('keydown', this.onKeyDown);
  };

  handleEmojiSelect = (emoji: BaseEmoji) => {
    const { native: reactionCode } = emoji;
    this.props.onSelect(reactionCode);
    this.closePicker();
  };

  renderEmojiPicker = () => (
    <div className={st(classes.root, { bw: !!this.props.forceBlackAndWhite })}>
      <Picker
        native
        color={null as any}
        showSkinTones={false}
        showPreview={false}
        onSelect={this.handleEmojiSelect}
        i18n={this.getPickerTranslations()}
      />
    </div>
  );

  renderEmojiPickerButton = () => (
    <Reaction
      cta
      label="+"
      icon={<AddEmojiIcon className={classes.icon} />}
      checked={false}
      labelPlacement={IconToggleLabelPlacement.START}
      onClick={this.openPicker}
    />
  );

  render() {
    const { mobile, t, forceBlackAndWhite } = this.props;
    const { isOpened } = this.state;
    return (
      <div
        tabIndex={1}
        aria-label={t('groups-web.a11y.reactions.more')}
        className={st(
          classes.root,
          { mobile, bw: forceBlackAndWhite } as any,
          this.props.className,
        )}
      >
        {mobile ? (
          <>
            {this.renderEmojiPickerButton()}
            <Modal
              inFullScreen={true}
              isOpen={isOpened}
              onRequestClose={this.closePicker}
              skin={ModalSkin.REACTIONS}
            >
              {this.renderEmojiPicker()}
            </Modal>
          </>
        ) : (
          <Popover
            appendTo="parent"
            placement="top-start"
            shown={isOpened}
            disableClickOutsideWhenClosed={true}
            onClickOutside={this.closePicker}
            className={classes.emojiPickerPopover}
          >
            <Popover.Element>{this.renderEmojiPickerButton()}</Popover.Element>
            <Popover.Content>{this.renderEmojiPicker()}</Popover.Content>
          </Popover>
        )}
      </div>
    );
  }
}

const enhance = compose(withTranslation(), withTPAConfig, withTheme);

export const EmojiPicker = enhance(EmojiPickerComponent);
EmojiPicker.displayName = 'EmojiPicker';
